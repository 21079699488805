import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { ethers } from "ethers";
import abiData from "./common/abi-data.json";
import whitelistWallets from "./common/whitelist-wallets.json";
import { NavBar } from "./components";
import axios from "axios";
import { keccak256 } from "@ethersproject/keccak256";
import { MerkleTree } from "merkletreejs";
import { utils } from "web3";
//`0xBe29aa4CC67F5A8E1Dc08C7876e58001Ea6A1980`
//`0x5c1A70211073BA137157D3832A489704d364BA6d`
const CONTRACT_ADDRESS = `0x5c1A70211073BA137157D3832A489704d364BA6d`;

function MintMachine() {
  const [isInvalidPupper, setInvalidPupper] = useState(false);
  const [walletAvailable, setWalletAvailable] = useState(true);
  const [pupperTraits, setTraits] = useState([]);
  const [showModal, toggleModal] = useState(false);
  const [walletError, toggleWalletError] = useState(false);
  const [mintError, toggleMintError] = useState(false);
  const mintFrame = useRef(null);

  useEffect(() => {
    window.addEventListener("message", handleParentMessage);

    return () => {
      window.removeEventListener("message", handleParentMessage);
    };
  }, []);

  useEffect(() => {
    if (pupperTraits.length === 3) {
      void mintPuppers();
    }
  }, [pupperTraits]);

  useEffect(() => {
    if (!window.ethereum) {
      setWalletAvailable(false);
      window.alert("No wallets found, please install Metamask Extension");
    } else {
      chainUpdateCallback();
    }

    window.ethereum?.on("chainChanged", chainUpdateCallback);

    return () => {
      window.ethereum?.removeListener("chainChanged", chainUpdateCallback);
    };
  }, []);

  async function chainUpdateCallback() {
    const web3Provider = new ethers.providers.Web3Provider(window?.ethereum);
    const { chainId, name } = await web3Provider.getNetwork();
    if (chainId === 1) {
      toggleWalletError(false);
    } else {
      toggleWalletError(true);
    }
  }

  const checkValidTraits = async (traits) => {
    const web3Provider = new ethers.providers.Web3Provider(window.ethereum);

    //CREATE SIGNER
    const walletAddress = await web3Provider
      .send("eth_requestAccounts", [])
      .then((res) => {
        return res[0];
      });
    const signer = web3Provider.getSigner(walletAddress);
    const contract = new ethers.Contract(CONTRACT_ADDRESS, abiData, signer);

    if (traits.length === 8) {
      try {
        const traitHash = await contract.generateTraitCombo(traits);
        const isUnique = await contract.isUnique(traitHash);
        const message = { isPupperValid: isUnique };

        mintFrame.current?.contentWindow.postMessage(
          JSON.stringify(message),
          "*"
        );
        if (!isUnique) {
          setInvalidPupper(true);
          toggleModal(true);
          setTimeout(() => {
            toggleModal(false);
            setInvalidPupper(false);
          }, 3000);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const mintPuppers = async () => {
    const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
    const { chainId, name } = await web3Provider.getNetwork();

    //CREATE SIGNER
    const walletAddress = await web3Provider
      .send("eth_requestAccounts", [])
      .then((res) => {
        return res[0];
      });

    const signer = web3Provider.getSigner(walletAddress);

    //CREATE CONTRACT INSTANCE
    const ruffionContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      abiData,
      signer
    );

    // console.log("WALLET ADDRESS: ", walletAddress);
    // console.log(web3Provider);

    const publicPrice = await ruffionContract.publicPrice();
    const quantity = await ruffionContract.MAX_MINTS_PER_ADDRESS();
    const totalPrice = publicPrice.mul(quantity);

    const whitelistAddresses = whitelistWallets;
    // console.log(whitelistAddresses.length);
    const leafNodes = whitelistAddresses.map((address) => keccak256(address));

    const merkleTree = new MerkleTree(leafNodes, keccak256, {
      sortPairs: true,
    });

    // Verify address
    const address = walletAddress;
    const leaf = keccak256(address);
    const hexProof = merkleTree.getHexProof(leaf);

    // console.log(hexProof);
    const parsedTraits = [];
    const parsedImages = [];

    pupperTraits.forEach((item) => {
      let dt = item.dogTrait.map((str) => parseInt(str));
      parsedTraits.push(dt);
      parsedImages.push(item.mintGifImage);
    });

    // console.log(parsedTraits);

    try {
      const result = await ruffionContract.mintPublic(
        parsedTraits[0],
        parsedTraits[1],
        parsedTraits[2],
        {
          value: totalPrice,
        }
      );
      //
      // console.log(result);

      if (result?.hash) {
        const tokenIds = await getTokenIdsFromTxReceipt(
          result?.hash,
          name,
          web3Provider
        );
        //console.log("TOKEN ID's:   ", tokenIds);

        const imageUploadResult = await uploadMintImages(
          name,
          tokenIds,
          parsedImages
        );

        if (imageUploadResult?.data) {
          toggleModal(false);
          setTraits([]);
        } else {
          toggleMintError(true);
          setTraits([]);
        }
      }
    } catch (e) {
      console.log("Metamask Err", e);
      toggleMintError(true);
      setTraits([]);
    }
    //error handling on metamask error event.
    //and whole minting data gets reset.
    //supply limit reached => exec reverted
    //user not on whitelist => exec reverted
    //limit reached for each wallet => exec reverted

    //update minting now popup in error state with message to try again.
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function getTxReceipt(tx, targetNetwork, provider) {
    while (true) {
      let receipt = await provider.getTransactionReceipt(tx);
      //console.log("TX RECEIPT", receipt);
      if (receipt && receipt.logs) {
        return receipt;
      }
      console.log("Waiting for mined block to include your trasaction...");
      await sleep(4000);
    }
  }

  async function getTokenIdsFromTxReceipt(tx, targetNetwork, provider) {
    const receipt = await getTxReceipt(tx, targetNetwork, provider);
    return receipt.logs.map((el) => utils.hexToNumber(el.topics[3]));
  }

  const handleParentMessage = async (ev) => {
    // console.log(ev);
    if (typeof ev.data === "string") {
      const data = JSON.parse(ev.data);
      if (data?.isMinting === true) {
        toggleModal(true);
      } else if (data?.checkTraits) {
        await checkValidTraits(data.checkTraits ?? []);
      } else {
        setTraits((oldArray) => [...oldArray, JSON.parse(ev.data)]);
      }
    }

    // if (ev.data === "CHECK_WALLET") {
    //   console.log("CHECK WALLET");
    // } else if (ev.data === "GET_WALLET_PROVIDER") {
    //   console.log("GET_WALLET_PROVIDER");
    //   await  getWalletAccount();
    // }
  };

  const uploadMintImages = async (network, tokenIds, images) => {
    if (pupperTraits.length) {
      const payload = {
        images,
        tokenIds,
      };
      try {
        return await axios.post(
          `https://ruffionreborn-api.fiveriverstech.net/mainnet/upload`,
          JSON.stringify(payload),
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
      } catch (e) {
        return "Error";
      }
    }
  };

  const getWalletAccount = () => {
    if (window.ethereum) {
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        return web3Provider
          .send("eth_requestAccounts", [])
          .then(async (res) => {
            // postChildMessage(data);
            return res[0];
          });
      } catch (ex) {
        console.log("Err:   ", ex);
      }
    }
  };

  const postChildMessage = (data) => {
    mintFrame.current?.contentWindow.postMessage(data, "*");
  };

  const errorBody = () => (
    <>
      <p
        className={"box-content"}
        style={{ color: "#c60f0f", fontSize: "1.3rem" }}
      >
        MINTING FAILED
      </p>

      <br />
      <p
        className={"box-content"}
        style={{
          color: "#b9bab9",
          maxWidth: "70%",
          textAlign: "center",
        }}
      >
        PLEASE TRY RELOADING THE PAGE
      </p>

      <button
        type={"text"}
        className={"refresh-btn"}
        onClick={() => window.location.reload()}
      >
        <p
          style={{
            color: "#1eb843",
            maxWidth: "70%",
            textAlign: "center",
            textDecoration: "underline",
          }}
        >
          Reload Now{" "}
        </p>
      </button>
    </>
  );

  const duplicateTraitError = () => (
    <>
      <p
        className={"box-content"}
        style={{ color: "#c60f0f", fontSize: "1.3rem" }}
      >
        BUMMER
      </p>

      <br />
      <p
        className={"box-content"}
        style={{
          color: "#b9bab9",
          maxWidth: "70%",
          textAlign: "center",
        }}
      >
        A PUPPER WITH THESE POWERS ALREADY EXISTS
      </p>

      <p
        style={{
          color: "#1eb843",
          maxWidth: "70%",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        Please try again with new traits.
      </p>
    </>
  );

  const metamaskNetworkError = () => (
    <>
      <p
        className={"box-content"}
        style={{ color: "#e3e3e3", textAlign: "center" }}
      >
        Please switch to ETH mainnet to begin minting your Puppers!
      </p>

      <br />

      <button
        className={"mint-btn"}
        style={{ marginTop: 0, fontSize: "1rem" }}
        onClick={() => switchNetwork()}
      >
        <p
          style={{
            textAlign: "center",
            fontWeight: 500,
            lineHeight: 0,
          }}
        >
          Switch Network
        </p>
      </button>
    </>
  );

  const switchNetwork = async () => {
    await window?.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ethers.utils.hexValue(1) }],
    });
  };

  return (
    <>
      <div id={"page-wrap"}>
        <NavBar />
        {/*<img src={HeaderLogo} alt={"logo"} className={"headerLogo"} />*/}

        <Box
          className={"content-box mint-restrict-window"}
          display={"flex"}
          p={3}
          textAlign={"center"}
          flexDirection={"column"}
          borderRadius={4}
        >
          <p className={"box-content"} style={{ color: "#1eb843" }}>
            Minting is not available on mobile. Please Mint Using a Desktop!
          </p>
        </Box>

        {walletError && (
          <div id={"loading-banner"}>{metamaskNetworkError()}</div>
        )}
        {walletAvailable && !walletError ? (
          <>
            <iframe
              src="https://ruffionreborn-api.fiveriverstech.net/mintmachine"
              ref={mintFrame}
              style={{
                width: "96vw",
                height: "80%",
                zIndex: 0,
                overflow: "hidden",
                overflowClipMargin: "unset",
              }}
              title="Mint machine"
            />

            {showModal ? (
              <div id="loading-banner">
                {mintError ? (
                  errorBody()
                ) : isInvalidPupper ? (
                  duplicateTraitError()
                ) : (
                  <>
                    <p
                      className={"box-content"}
                      style={{ color: "#1eb843", fontSize: "1.3rem" }}
                    >
                      MINTING IN PROGRESS
                    </p>
                    <br />
                    <p
                      className={"box-content"}
                      style={{
                        color: "#b9bab9",
                        maxWidth: "70%",
                        textAlign: "center",
                      }}
                    >
                      PLEASE DO NOT REFRESH OR LEAVE THIS PAGE
                    </p>
                  </>
                )}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}

export default MintMachine;
