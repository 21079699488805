import React, { useEffect, useState } from "react";
import HeroLogo from "./images/logo-header-glow.png";

import BurgerIcon from "./images/burgerIcon.svg";
import { useHistory } from "react-router";
import VidPoster from "./images/vid-poster.jpg";
import MintPoster from "./images/mint-poster.jpg";
import GamePlay from "./media/gameplay.mp4";
import MintVideo from "./media/mintmod.mp4";
import { FooterView, NavBar } from "./components";
import { Box, Grid } from "@mui/material";

const LitePaper = () => {
  return (
    <>
      <div id={"page-wrap"}>
        <NavBar />
        <div className="super-content-wrapper">
          <Box
            style={{ backgroundColor: "rgb(0 0 0 / 50%)" }}
            borderRadius={3}
            px={3}
            py={1}
          >
            <div className={"header-image-container"}>
              <img
                alt={"section-header"}
                src={HeroLogo}
                className={"litepaper-header-image"}
              />
            </div>
            <div className="super-content max-width">
              <div className="notion-header-content">
                <div className="notion-header-wrapper">
                  <h1 className={"notion-header"}>
                    Ruffion Reborn Litepaper 10.3.22
                  </h1>
                </div>
              </div>
              <article className="notion-root">
                <h1 className={"notion-heading"}>
                  <span className={"notion-semantic-string"}>
                    <span>Overview</span>
                  </span>
                </h1>

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        Ruffion Reborn is the first homebrew cartridge for the
                        TreasureDAO ecosystem. Our goal is to create a
                        decentralized game that can be maintained, expanded and
                        owned by the community.{" "}
                      </span>
                    </span>
                  </p>
                </div>

                <br />

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        Ruffion Reborn is not only be a playable PvP game, but
                        it will also enable developers and other interested
                        users in the ecosystem to use it as a tutorial project
                        which can be used by the community as a starting point
                        to create their own games.{" "}
                      </span>
                    </span>
                  </p>
                </div>
                <br />

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        The Puppers will be minted under CC0 to enable the
                        community to use them in mods or their own projects. The
                        ultimate goal for this project is to create a game that
                        will always be available for players to download and
                        play, and expand upon, or revive.
                      </span>
                    </span>
                  </p>
                </div>

                {/*<div className="notion-text" style={{fontWeight: '500'}}>*/}
                {/*    <p className="notion-text__content">*/}
                {/*        <a target={"blank"} href={'https://ruffion-reborn.super.site/part-1'}>*/}
                {/*<span className={"notion-semantic-string"}>*/}
                {/*    <span>*/}
                {/*        Ruffion Reborn - Part 1*/}
                {/*    </span>*/}
                {/*</span>*/}
                {/*        </a>*/}
                {/*    </p>*/}
                {/*</div>*/}
                <h1 className={"notion-heading"}>
                  <span className={"notion-semantic-string"}>
                    <span>Current Status</span>
                  </span>
                </h1>

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        The website is live! If you’re reading this you’ve
                        already seen
                      </span>
                    </span>
                  </p>
                </div>
                <div className="notion-text" style={{ fontWeight: "500" }}>
                  <p className="notion-text__content">
                    <a target={"blank"} href={"https://www.ruffionreborn.xyz/"}>
                      <span className={"notion-semantic-string"}>
                        <span>https://www.ruffionreborn.xyz 🐶</span>
                      </span>
                    </a>
                  </p>
                </div>
                <br />
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        We had been working on getting a playable demo out which
                        was released to the community a little while back. We
                        want to show everyone what has been built so far,
                        allowing you a chance to try it out before you mint
                        anything. The community gave us great feedback based on
                        which an updated version of the demo has been released.
                        It is currently available for download on the Ruffion
                        Reborn website.
                      </span>
                    </span>
                  </p>
                </div>
                <br />
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        The demo is available to download for PC, Mac, Android.
                        It is also available to play online (not on mobile) on
                        the website!
                      </span>
                    </span>
                  </p>
                </div>

                <br />
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        There are more improvements that will be made for the
                        actual game. For example, it will be built in Godot as
                        we want to be able to opensource the project. Godot is
                        completely free to use for everyone and there are no
                        license/subscription restrictions.
                      </span>
                    </span>
                  </p>
                </div>

                <br />
                <div className="notion-embed">
                  <div className="notion-embed__content">
                    {/*<div style={{width: "100%", paddingBottom: '75%'}}>*/}
                    {/*<div style={{width: "100%", height: "100%"}}>*/}
                    {/*    <iframe className={"notion-yt"}*/}
                    {/*            src="https://www.youtube.com/embed/V5DZKZhx41o"*/}
                    {/*            title="YouTube video player" frameBorder="0"*/}
                    {/*            loading={"lazy"}*/}
                    {/*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                    {/*            allowFullScreen></iframe>*/}
                    {/*</div>*/}
                    {/*   */}
                    {/*</div>*/}
                    <video
                      poster={VidPoster}
                      width="100%"
                      height="auto"
                      className={"videoPlayer"}
                      controls
                      loop
                      muted
                      playsinline
                    >
                      <source src={GamePlay} />
                    </video>
                  </div>
                </div>

                <br />

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        The Puppers are all CC0 so once you mint your Puppers,
                        you can do whatever you like with them. Other members of
                        the community can also expand on the Puppers and create
                        derivatives.{" "}
                      </span>
                    </span>
                  </p>
                </div>

                <br />

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        The fact that Ruffion Reborn is going to be living in
                        the $MAGIC ecosystem allows us to integrate with other
                        TreasureDAO projects, such as Life, SmolBrains, Legions,
                        and more.{" "}
                      </span>
                    </span>
                  </p>
                </div>

                <br />

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        Imagine being able to construct a team with a Pupper,
                        Legion and SmolBrain each having different strengths and
                        weaknesses, based on which they can or cannot use
                        certain cards, as well as having unique skills.
                      </span>
                    </span>
                  </p>
                </div>

                <br />

                <div className="notion-embed">
                  <div className="notion-embed__content">
                    {/*<div style={{width: "100%", paddingBottom: '75%'}}>*/}
                    {/*    <div style={{width: "100%", height: "100%"}}>*/}
                    {/*        <iframe className={"notion-yt"}*/}
                    {/*                src="https://www.youtube.com/embed/YmuE6Ce4VlE?feature=oembed"*/}
                    {/*                title="YouTube video player" frameBorder="0"*/}
                    {/*                loading={"lazy"}*/}
                    {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                    {/*                allowFullScreen></iframe>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <video
                      poster={MintPoster}
                      width="100%"
                      height="auto"
                      className={"videoPlayer"}
                      controls
                      loop
                      muted
                      playsinline
                    >
                      <source src={MintVideo} />
                    </video>
                  </div>
                </div>

                <br />

                <br />
                <h1 className={"notion-heading"}>
                  <span className={"notion-semantic-string"}>
                    <span>Changes in Minting</span>
                  </span>
                </h1>

                <br />

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        We had initially (a long time back) thought to do a free
                        mint. However, the state of the ecosystem has changed.
                        We spoke to our community and friends to get a sense of
                        how best to approach our mint.{" "}
                      </span>
                    </span>
                  </p>
                </div>

                <br />
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        Here’s our plan. We’ll be minting later this month.
                        Everyone will be able to create their own Puppers to
                        mint.
                      </span>
                    </span>
                  </p>
                </div>

                <br />
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        Minting will not be free (sorry 😞) and we will be
                        releasing 3333 Puppers initially. The mint price will be
                        0.03 ETH per Pupper.
                      </span>
                    </span>
                  </p>
                </div>

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        TreasureDAO communities will get WL spots and we will be
                        WL’ing folks in our discord as well.
                      </span>
                    </span>
                  </p>
                </div>

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>3000 Puppers will be available for mint.</span>
                    </span>
                  </p>
                </div>

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>303 Puppers will be used for giveaways, etc.</span>
                    </span>
                  </p>
                </div>

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        30 Puppers will be reserved for the team. These will be
                        minted after 3000 Puppers have been minted.
                      </span>
                    </span>
                  </p>
                </div>

                <br />
                <h1 className={"notion-heading"}>
                  <span className={"notion-semantic-string"}>
                    <span>A Roadmap of Sorts</span>
                  </span>
                </h1>
                <br />
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      This part remains the same from the older versions of the
                      litepaper (if you’ve read it). After these first 5 steps
                      are completed, we’ll be in a great place with Ruffion
                      Reborn and ready for the community to fully take it over.
                    </span>
                  </p>
                </div>
                <h2 className={"notion-heading"}>
                  <span className={"notion-semantic-string"}>
                    <span>1. Godot Port</span>
                  </span>
                </h2>
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        The demo was built in Unity, but as mentioned above, we
                        will be moving forward with Godot. We plan to port the
                        game over to Godot because we want to make the game
                        client available on Github, GitLab, as well as IPFS,
                        enabling members of the community to get access to the
                        code, extend it, build new things on top of it, etc.
                      </span>
                    </span>
                  </p>
                </div>

                <h2 className={"notion-heading"}>
                  <span className={"notion-semantic-string"}>
                    <span>2. Enabling Extension</span>
                  </span>
                </h2>
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        After the main game is ported over, The Ruffian Reborn
                        team plans to create tools that will make it easy for
                        community members to expand upon the game by adding new
                        cards and new gear.
                      </span>
                    </span>
                  </p>
                </div>
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        It shouldn’t be too be hard even without the tools
                        because of the details outlined in Section 4. below.{" "}
                      </span>
                    </span>
                  </p>
                </div>

                <h2 className={"notion-heading"}>
                  <span className={"notion-semantic-string"}>
                    <span>3. Integrating Other Collections</span>
                  </span>
                </h2>
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        Once the community has the ability to create
                        derivatives, Ruffion Reborn will explore integration
                        with at least one other collection from the $MAGIC
                        ecosystem. We want to create a template which allows
                        other developed to integrate any number of collections
                        in the $MAGIC ecosystem or those on the $ETH Mainnet and
                        are CC0.
                      </span>
                    </span>
                  </p>
                </div>

                <h2 className={"notion-heading"}>
                  <span className={"notion-semantic-string"}>
                    <span>4. Road to Decentralization (Architecture)</span>
                  </span>
                </h2>
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        We have always been motivated to create a game that
                        would “live forever”, or more practically, always be
                        there for whoever wanted to play it. Our CEO, Ali
                        Husain, has talked about why it is important ine of my
                        Bored Ape Games on Chain newsletters. If you’re
                        interested you can read it here: or here:
                      </span>
                    </span>
                  </p>
                </div>
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        In order for that to happen, especially for a game like
                        Ruffion Reborn which will have a PvP aspect, we’ll be
                        splitting the components out in a way that it will be
                        possible to publish to take a couple of examples, patch
                        notes and match history of all matches ever played
                        on-chain or on decentralized storage. That way, the
                        first thing the client will fetch are the latest patch
                        notes and match history, verify they were published by
                        the Ruffion Reborn DAO (more on this in Section 5.) and
                        then proceed from there.
                      </span>
                    </span>
                  </p>
                </div>
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        This will also be the way to check for integrations that
                        have been done so far, get a full list of cards and gear
                        etc.
                      </span>
                    </span>
                  </p>
                </div>
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>The design is currently in progress.</span>
                    </span>
                  </p>
                </div>

                <h2 className={"notion-heading"}>
                  <span className={"notion-semantic-string"}>
                    <span>5. DAO and Governance</span>
                  </span>
                </h2>
                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        This is what I see as the last piece. Although the
                        community will be involved from the very beginning in
                        terms of contributions and direction of the project,
                        there is a need to get Ruffion Reborn to a point where a
                        base has been built (as described in the sections above)
                        so that the community can choose how they want it to
                        grow and which direction to take it in.
                      </span>
                    </span>
                  </p>
                </div>

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        To that point the establishment of the DAO will put in
                        place a team that will have ownership of the smart
                        contracts, Ruffion Reborn wallet, etc. and will take the
                        game forward with the community.
                      </span>
                    </span>
                  </p>
                </div>

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        The architecture of the game will be such that it will
                        be possible for someone to take the game and publish
                        their own “patches” and add custom characters, gears,
                        cards, etc. or remove stuff, but it will have to be done
                        through “unofficial” contracts, so it will be easy for
                        players to distinguish exactly what version they are
                        playing.{" "}
                      </span>
                    </span>
                  </p>
                </div>

                <div className="notion-text">
                  <p className="notion-text__content">
                    <span className={"notion-semantic-string"}>
                      <span>
                        I also hope this serves to encourage others to try out
                        different things, maybe take the code and create a
                        completely new and/or different game, maybe use the
                        assets differently. There’s a lot that can be done.{" "}
                      </span>
                    </span>
                  </p>
                </div>
              </article>
            </div>
          </Box>
        </div>
        <FooterView />
      </div>
    </>
  );
};

export default LitePaper;
