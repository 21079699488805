import { Container } from "@mui/material";

import DiscordLogo from "../images/discord.png";
import TwitterLogo from "../images/download.png";
export const FooterView = () => {
  return (
    <footer>
      <p className="title" style={{ fontSize: "1rem", lineHeight: "40px" }}>
        Ruffion Reborn
      </p>
      <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
        <a
          href={"https://discord.com/invite/wVGEPaFz2G"}
          style={{ height: "1.2rem" }}
          target={"_blank"}
        >
          <img src={DiscordLogo} alt={"discord"} className={"headerBtnImg"} />
        </a>

        <a
          href={"https://twitter.com/RuffionReborn"}
          style={{ height: "1.2rem" }}
          target={"_blank"}
        >
          <img src={TwitterLogo} alt={"twitter"} className={"headerBtnImg"} />
        </a>
      </div>
    </footer>
  );
};

export default FooterView;
