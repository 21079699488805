import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './fonts/Montserrat/Montserrat-Regular.ttf';
import './fonts/DigitalDisco/DigitalDisco.ttf';

import App from './App';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement
);
