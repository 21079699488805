import React, { useEffect, useState } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { useHistory } from "react-router";
import Select from "react-select";
import { FooterView, NavBar } from "./components";
import { Box } from "@mui/material";

const unityContext = new UnityContext({
  loaderUrl: "/build/uWeb.loader.js",
  dataUrl: "/build/uWeb.data.unityweb",
  frameworkUrl: "/build/uWeb.framework.js.unityweb",
  codeUrl: "/build/uWeb.wasm.unityweb",
});

function GameDemo() {
  const [progression, setProgression] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(function () {
    unityContext.on("progress", function (progression) {
      setProgression(progression);
    });
    unityContext.on("loaded", function () {
      setIsLoaded(true);
    });
  }, []);

  // Allow scrolling on canvas without affecting mouse event
  document.addEventListener("wheel", onScroll, false);
  document.addEventListener("mousemove", onMouse, false);
  const content = document.getElementsByTagName("canvas");

  function onMouse() {
    content[0].style.pointerEvents = "auto";
  }
  function onScroll() {
    content[0].style.pointerEvents = "none";
  }
  const history = useHistory();
  var downloadFile = (type) => {
    const anchor = document.createElement("a");
    anchor.href = `https://ruffionreborn.xyz/${type.value}`;
    anchor.download = type.value;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  return (
    <>
      {progression * 100 < 100 ? (
        <p
          style={{
            color: "white",
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "40%",
          }}
        >
          Loading Application... {Math.round(progression * 100)}%
        </p>
      ) : (
        ""
      )}
      <div>
        <NavBar />
        <Unity
          unityContext={unityContext}
          style={{
            visibility: isLoaded ? "visible" : "hidden",
            width: "100vw",
            height: "90vh",
            // pointerEvents:'none',
          }}
        />
        <FooterView />
      </div>
    </>
  );
}

export default GameDemo;
