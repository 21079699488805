import React, { useRef, useEffect, useState } from "react";
import { ethers } from "ethers";
import { useTheme } from "@mui/material/styles";
import Masonry from "@mui/lab/Masonry";

import HeroLogo from "./images/logo-header-glow.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container, Box, Paper, Grid } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router";
import { FooterView, NavBar } from "./components";
import Slide1 from "./images/f1.gif";
import Slide2 from "./images/f2.gif";
import Slide3 from "./images/f3.gif";
import Slide4 from "./images/f4.gif";
import Box1Image from "./images/box1.png";
import Box2Image from "./images/box2.png";
import Box3Image from "./images/box3.gif";
import Box4Image from "./images/box4.gif";

export default function Home() {
  let [current, setCurrent] = useState(0);
  const history = useHistory();

  let containerRef = useRef(null);
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div ref={containerRef} className={"page-wrap"}>
        <NavBar />
        <main>
          <Box
            className={"content-box"}
            borderRadius={3}
            lineHeight={1}
            display={"flex"}
            width={"auto"}
            mb={1}
            flexDirection={"column"}
            textAlign={"center"}
          >
            <Box borderColor={"red"} pb={7}>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
                className={"header-image-container"}
              >
                <img alt={"hero"} className={"heroImage"} src={HeroLogo} />
              </div>
              <button
                className={"mint-btn"}
                onClick={() => history.push("/mint-machine")}
              >
                MINT YOUR PUPPERS
              </button>
            </Box>

            <p className={"sub-heading"}>OVERVIEW</p>
          </Box>

          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <Box className={"content-box"} borderRadius={3} px={3} py={1}>
                <p className={"box-heading"}>WHAT IS RUFFION REBORN?</p>
                <Grid container spacing={2} alignItems={"flex-start"}>
                  <Grid item sm={12} md={6}>
                    <p className={"box-content"}>
                      An open-source peer-to-peer turn based, deck building game
                      that you can play and/or use to build your own Treasure
                      cartridge!
                    </p>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <img
                      alt={"story3"}
                      className={"storyImage"}
                      src={Box1Image}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6}>
              <Box className={"content-box"} borderRadius={3} px={3} py={1}>
                <p className={"box-heading"}>
                  THE GAME - Create, Customize, and Dominate
                </p>
                <Grid container spacing={2} alignItems={"flex-start"}>
                  <Grid item sm={12} md={6}>
                    <p className={"box-content"}>
                      <ul>
                        <li>
                          Choose your mighty Puppers wisely! Your team
                          compositions matter!
                        </li>
                        <li>
                          Customize and outfit your Pupper decks to outsmart
                          your enemies! Your strategies and choices will
                          determine your success!
                        </li>
                        <li>
                          Compete and Dominate the Pupper Arenas for glory and
                          dominion over all Ruffion!{" "}
                        </li>
                      </ul>
                    </p>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <img
                      alt={"story3"}
                      className={"storyImage"}
                      src={Box2Image}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} style={{ marginTop: 10 }}>
              <Box className={"content-box"} borderRadius={3} px={3} py={1}>
                <p className={"box-heading"}>FOR BUILDERS AND MODDERS</p>

                <Grid container spacing={2} alignItems={"flex-start"}>
                  <Grid item sm={12} md={6}>
                    <p className={"box-content"}>
                      The first homebrew cartridge for Treasure. Built to
                      encourage builders & modders in the Treasure ecosystem to
                      create their own versions or completely new games!
                      <br />
                      <br /> The best games come from the community - Dota,
                      Autochess, etc.
                    </p>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <img
                      alt={"story3"}
                      className={"storyImage"}
                      src={Box3Image}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} style={{ marginTop: 10 }}>
              <Box className={"content-box"} borderRadius={3} px={3} py={1}>
                <p className={"box-heading"}>GOING FORWARD</p>
                <Grid container spacing={2} alignItems={"flex-start"}>
                  <Grid item sm={12} md={6}>
                    <p className={"box-content"}>
                      <ul>
                        <li>Release the new PvP game with P2P multiplayer.</li>
                        <li> Release more OP cards & break things 😂</li>
                        <li>Integrations with our Treasure fam.</li>
                        <li>
                          Get the community to participate in creating/balancing
                          cards.
                        </li>
                      </ul>
                    </p>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <img
                      alt={"story3"}
                      className={"storyImage"}
                      src={Box4Image}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Box
            bgcolor={"rgb(0 0 0 / 40%)"}
            borderRadius={3}
            lineHeight={1}
            display={"flex"}
            width={"auto"}
            mt={5}
            mb={1}
            justifyContent={"center"}
            textAlign={"center"}
          >
            <p className={"sub-heading"}>THE STORY</p>
          </Box>

          <Masonry columns={{ sm: 1, md: 2 }} spacing={2} defaultHeight={400}>
            <Box className={"mason-box"} borderRadius={3} px={3} py={1}>
              <p className={"box-heading"}>3737 A.D.</p>

              <Grid container spacing={2} alignItems={"flex-start"}>
                <Grid item sm={12} md={7}>
                  <img alt={"story1"} className={"storyImage"} src={Slide1} />
                </Grid>
                <Grid item sm={12} md={5}>
                  <p className={"box-content"}>
                    Pupper-1 begins its decent to Ruffion. A world once
                    peacefully inhabited by the Puppers & their ancestors, until
                    the Humans came.
                  </p>
                </Grid>
              </Grid>
            </Box>
            <Box pt={{ sm: 3, md: 15 }}>
              <Box className={"mason-box"} borderRadius={3} px={3} py={1}>
                <p className={"box-heading"}>Havoc Wrecked</p>
                <Grid container spacing={2} alignItems={"flex-start"}>
                  <Grid item sm={12} md={7}>
                    <img alt={"story1"} className={"storyImage"} src={Slide2} />
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <p className={"box-content"}>
                      The technological advancements they brought with them came
                      at great cost. Humans, as is their nature, gave into
                      infighting & self-destructive tendencies, and wrecked
                      havoc.
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box pt={{ sm: 3, md: 10 }}>
              <Box className={"mason-box"} borderRadius={3} px={3} py={1}>
                <p className={"box-heading"}>Cryostasis</p>
                <Grid container spacing={2} alignItems={"flex-start"}>
                  <Grid item sm={12} md={7}>
                    <img alt={"story3"} className={"storyImage"} src={Slide3} />
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <p className={"box-content"}>
                      Knowing the planet was doomed, in one last act of
                      selflessness, the Humans built the satellite, Pupper 1 and
                      sent into orbit, 10,000 Puppers in cryostasis. To be woken
                      when Pupper 1 landed back on a planet that could be
                      reborn.
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box pt={{ sm: 3, md: 10 }}>
              <Box className={"mason-box"} borderRadius={3} px={3} py={1}>
                <p className={"box-heading"}>A Mysterious Threat</p>
                <Grid container spacing={2} alignItems={"flex-start"}>
                  <Grid item sm={12} md={7}>
                    <img alt={"story3"} className={"storyImage"} src={Slide4} />
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <p className={"box-content"}>
                      Returned to their home planet, the Puppers begin
                      rebuilding Ruffion, where they now face a mysterious new
                      threat.
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Masonry>
        </main>
        <FooterView />
      </div>
    </>
  );
}
