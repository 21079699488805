import HeaderLogo from "../images/logo-header.png";
import DiscordLogo from "../images/discord.png";
import TwitterLogo from "../images/download.png";
import Select from "react-select";
import BurgerIcon from "../images/burgerIcon.svg";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";

const options = [
  { value: "ruffion-windows.rar", label: "Windows" },
  { value: "ruffion-mac.dmg", label: "Mac" },
  { value: "ruffion-android.apk", label: "Android" },
];
let customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#06212f",
    boxShadow: "-1px 0px 11px 3px #024a71",
    border: "none",
    fontSize: "0.8rem",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
    fontFamily: "RobotoLight",
  }),
  menu: (provided, state) => ({
    ...provided,

    color: "white",
    backgroundColor: "#06212f",
    fontFamily: "RobotoLight",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    color: "white",
    fontFamily: "RobotoLight",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#06212f",
    border: "1px solid #06212f",
    fontSize: "0.8rem",
    zIndex: 10,
    "&:hover": {
      backgroundColor: "#061e2b",
      border: "1px solid #2291cf",
    },
  }),
};

export const NavBar = () => {
  let [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const downloadFile = (type) => {
    const anchor = document.createElement("a");
    anchor.href = `https://ruffionreborn.xyz/${type.value}`;
    anchor.download = type.value;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <>
      <Menu pageWrapId={"page-wra2p"} isOpen={menuOpen}>
        <div className="select-dropdown">
          <Select
            options={options}
            blurInputOnSelect={true}
            isSearchable={false}
            menuPlacement={"auto"}
            onChange={(e) => downloadFile(e)}
            placeholder={"Download Demo"}
            classNamePrefix={"HTSelect"}
            styles={customStyles}
          />
        </div>

        <button
          style={{ zIndex: "10" }}
          className={"back-btn"}
          onClick={() => history.push("/")}
        >
          Home
        </button>
        <button
          style={{ zIndex: "10" }}
          className={"back-btn"}
          onClick={() => history.push("/demo")}
        >
          Play Demo
        </button>
        <button
          style={{ zIndex: "10" }}
          className={"back-btn"}
          onClick={() => history.push("/litepaper")}
        >
          Read Litepaper
        </button>
        {/*<button*/}
        {/*  style={{ zIndex: "10" }}*/}
        {/*  className={"back-btn"}*/}
        {/*  onClick={() => history.push("/mint-machine")}*/}
        {/*>*/}
        {/*  Mint Machine*/}
        {/*</button>*/}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
            width: "fit-content",
            border: "none",
            marginTop: "4rem",
          }}
        >
          <a
            href={"https://discord.com/invite/wVGEPaFz2G"}
            style={{ height: "1.2rem", zIndex: "10" }}
            target={"_blank"}
            className={"header-social-btn"}
          >
            <img src={DiscordLogo} alt={"discord"} className={"headerBtnImg"} />
          </a>

          <a
            href={"https://twitter.com/RuffionReborn"}
            style={{ height: "1.2rem", zIndex: "10" }}
            target={"_blank"}
            className={"header-social-btn"}
          >
            <img src={TwitterLogo} alt={"twitter"} className={"headerBtnImg"} />
          </a>
        </div>
      </Menu>
      <div className={"nav"}>
        {location.pathname === "/demo" ? (
          <a href={"/"} style={{ textDecoration: "none" }}>
            <button className={"back-btn"} onClick={() => history.back()}>
              &#10094; Back
            </button>
          </a>
        ) : (
          <a href={"/"}>
            {" "}
            <img src={HeaderLogo} alt={"logo"} className={"headerLogo"} />
          </a>
        )}

        <div
          style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}
          className={"header-content-md"}
        >
          <div className="select-dropdown">
            <Select
              options={options}
              blurInputOnSelect={true}
              isSearchable={false}
              menuPlacement={"auto"}
              onChange={(e) => downloadFile(e)}
              placeholder={"Download Demo"}
              classNamePrefix={"HTSelect"}
              styles={customStyles}
            />
          </div>

          <button
            style={{ zIndex: "10" }}
            className={"back-btn"}
            onClick={() => history.push("/demo")}
          >
            Game Demo
          </button>

          {/*<button*/}
          {/*  style={{ zIndex: "10" }}*/}
          {/*  className={"back-btn"}*/}
          {/*  onClick={() => history.push("/mint-machine")}*/}
          {/*>*/}
          {/*  Mint Machine*/}
          {/*</button>*/}

          <button
            style={{ zIndex: "10" }}
            className={"back-btn"}
            onClick={() => history.push("/litepaper")}
          >
            Read Litepaper
          </button>
          <a
            href={"https://discord.com/invite/wVGEPaFz2G"}
            style={{ height: "1.2rem", zIndex: "10" }}
            target={"_blank"}
            className={"header-social-btn"}
          >
            <img src={DiscordLogo} alt={"discord"} className={"headerBtnImg"} />
          </a>

          <a
            href={"https://twitter.com/RuffionReborn"}
            style={{ height: "1.2rem", zIndex: "10" }}
            target={"_blank"}
            className={"header-social-btn"}
          >
            <img src={TwitterLogo} alt={"twitter"} className={"headerBtnImg"} />
          </a>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          className={"header-content-xs"}
        >
          <button
            style={{ zIndex: "10" }}
            className={"back-btn"}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <img src={BurgerIcon} alt={"twitter"} className={"headerBtnImg"} />
          </button>
        </div>
      </div>
    </>
  );
};

export default NavBar;
