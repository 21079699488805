import React from "react";
import {
  BrowserRouter,
  HashRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import Home from "./Home";
import GameDemo from "./GameDemo";
import LitePaper from "./LitePaper";
import "./style.scss";
import MintMachine from "./MintMachine";
import { Container } from "@mui/material";

export default function App() {
  return (
    <Router basename="/">
      <Container maxWidth="false" className={"wrapper"}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/litepaper">
            <LitePaper />
          </Route>
          <Route exact path="/mint-machine">
            <MintMachine />
          </Route>
          <Route path="/demo">
            <GameDemo />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
}
